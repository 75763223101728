import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    input,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 *
 * USAGE:
 * <div aixTooltip aixTooltipContent="Tooltip content <b>here</b>.">Foo bar</div>
 *
 */
@Directive({
    selector: '[aixTooltip]',
    standalone: true
})
export class AixTooltipDirective implements OnInit, AfterViewInit, OnDestroy {
    aixTooltipContent = input.required<string>();
    aixSize = input<string>();
    aixColor = input<string>();
    aixIcon = input<string>('fa-circle-info');

    static aixTooltipHtml: HTMLDivElement = document.createElement('div');
    static firstCall = true;
    aixTooltipIcon: HTMLSpanElement = document.createElement('span');

    aixTooltipIconListener: () => void;

    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        if (AixTooltipDirective.firstCall) {
            AixTooltipDirective.firstCall = false;
            this.initializeAixTooltipHtml();
        }
    }

    initializeAixTooltipHtml(): any {
        /**
         * Append tooltip with content to element
         */
        AixTooltipDirective.aixTooltipHtml.setAttribute('class', 'aixTooltip__tooltip');
        AixTooltipDirective.aixTooltipHtml.setAttribute('data-testing', 'aixTooltip');
        this.renderer.appendChild(
            document.querySelector('body'),
            AixTooltipDirective.aixTooltipHtml
        );
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.hideTooltip();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.hideTooltip();
                }
            })
        );

        /**
         * Append tooltip icon to element
         */
        this.aixTooltipIcon.classList.add('tooltip__icon');
        this.aixTooltipIcon.classList.add('fa-light');
        this.aixTooltipIcon.classList.add(this.aixIcon());
        if (this.aixColor()) {
            this.aixTooltipIcon.style.color = <string>this.aixColor();
        }
        if (this.aixSize()) {
            this.aixTooltipIcon.style.fontSize = <string>this.aixSize();
        }
        this.renderer.appendChild(this.el.nativeElement, this.aixTooltipIcon);

        this.hideTooltip();
    }

    ngAfterViewInit() {
        /**
         * Bind mouseenter event to the tooltip icon span tag
         * This ensures that only hovering over the icon displays the tooltip
         */

        this.aixTooltipIconListener = this.renderer.listen(
            this.el.nativeElement.querySelector('.tooltip__icon'),
            'mouseenter',
            event => {
                AixTooltipDirective.aixTooltipHtml.innerHTML = this.aixTooltipContent();
                this.showTooltip(event);
            }
        );
    }

    ngOnDestroy() {
        if (this.aixTooltipIconListener) {
            this.aixTooltipIconListener();
        }

        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private positionTooltip(event: MouseEvent) {
        const globalX = (event.target as HTMLElement).getBoundingClientRect().left;
        const tooltipWidth = AixTooltipDirective.aixTooltipHtml.offsetWidth;
        let tooltipX = globalX - tooltipWidth;
        if (tooltipX < 0) {
            tooltipX = globalX;
        }

        const someMargin = 15; // les than 15 produces blinking issues in some scenarios. @see: https://github.com/trade-platform/trade-platform/issues/1559 for more info
        const globalY = event.pageY;

        let tooltipY: number;
        const tooltipHeight = AixTooltipDirective.aixTooltipHtml.offsetHeight;
        const tooltipYOverflow = globalY + tooltipHeight;
        if (tooltipYOverflow > window.scrollY + window.innerHeight) {
            tooltipY = globalY - (tooltipHeight + someMargin);
        } else {
            tooltipY = globalY + someMargin;
        }

        return {
            left: tooltipX,
            top: tooltipY
        };
    }

    private showTooltip(e: MouseEvent) {
        AixTooltipDirective.aixTooltipHtml.setAttribute(
            'style',
            `left: ${this.positionTooltip(e).left}px; top: ${this.positionTooltip(e).top}px;`
        );
    }

    private hideTooltip() {
        /**
         * Hiding the tooltip by positioning it offscreen so that we can get the width of the tooltip
         * even when it is not visible.
         */
        AixTooltipDirective.aixTooltipHtml.setAttribute('style', 'left: -10000px');
    }
}
