import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AixDeactivateGuardComponent } from '../guard-interfaces/deactivate.guard.interface';
import { AixAuthService } from '../../services/auth-service';
import { take } from 'rxjs/operators';

@Injectable()
export class ComponentDeactivateGuard {
    constructor(private authService: AixAuthService, private router: Router) {}

    canDeactivate(
        component: AixDeactivateGuardComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if (this.authService.forceLogout) {
            return true;
        } else {
            const can = component.canDeactivate(nextState);

            // Observable
            if (can instanceof Observable) {
                return new Observable(o => {
                    can.pipe(take(1)).subscribe((c: boolean) => {
                        this.checkDeactivateValue(c);

                        o.next(c);
                    });
                });
            }

            // Promise
            if (can instanceof Promise) {
                return new Promise((resolve, reject) => {
                    can.then((c: boolean) => {
                        this.checkDeactivateValue(c);

                        resolve(c);
                    });
                });
            }

            // Boolean
            this.checkDeactivateValue(can);
            return can;
        }
    }

    /**
     * Manually routes back to the current route to fix the browser history when the canDeactivate guard returns false;
     * NOTE: This allows the user to manually navigatge to the same url twice, even when the canDeactivate guard returns false;
     * @param can {boolean} - the value returned by the canDeactivate component function;
     */
    private checkDeactivateValue(can: boolean): void {
        if (!can) {
            this.router.navigate([decodeURIComponent(this.router.url)]);
        }
    }
}
