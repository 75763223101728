<div #messageBoxElement>
@for (notification of systemNotifications; track notification) {
    <div>
        @for (message of notification.messages; track message) {
        <div>
            <aix-notification
                #notification
                [status]="message.status"
                [isOpen]="true"
                [canClose]="true"
                class="aix-system-notifications"
                (onCloseNotification)="onCloseNotification()"
            >
            <span [innerHTML]="message.message | aixSanitize"></span>
            </aix-notification>
        </div>
        }
    </div>
}
</div>
