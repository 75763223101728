import { AixAction, ErrorWrapper, type } from '@trade-platform/ui-shared';

export class DocumentValueActionTypes {
    static readonly LOAD_DOCUMENT_VALUES = type('[Document Values] Load Document Values');
    static readonly LOAD_DOCUMENT_VALUES_SUCCESS = type(
        '[Document Values] Load Document Values Success'
    );
    static readonly LOAD_DOCUMENT_VALUES_FAIL = type(
        '[Document Values] Load Document Values Failure'
    );
}

export type DocumentValueActions =
    | LoadDocumentValuesAction
    | LoadDocumentValuesSuccessAction
    | LoadDocumentValuesFailureAction;

export class LoadDocumentValuesAction implements AixAction {
    readonly type = DocumentValueActionTypes.LOAD_DOCUMENT_VALUES;

    constructor(public payload: string) {}
}

export class LoadDocumentValuesSuccessAction implements AixAction {
    readonly type = DocumentValueActionTypes.LOAD_DOCUMENT_VALUES_SUCCESS;

    constructor(public payload: string[]) {}
}

export class LoadDocumentValuesFailureAction implements AixAction {
    readonly type = DocumentValueActionTypes.LOAD_DOCUMENT_VALUES_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
