@if (records() && records().length > paginationPageSize()) {
<div class="aix-pagination">
    <button
        #buttons
        class="aix-pagination__button u-color-tertiary-black -first br-primary__color"
        (click)="goToFirstPage.emit()"
    >
        First
    </button>
    <button
        #buttons
        class="aix-pagination__button u-color-tertiary-black -arrow br-primary__color"
        (click)="goToPreviousPage.emit()"
        aria-label="Previous"
    >
        <i class="fa-regular fa-chevron-left"></i>
    </button>
    @for (p of pages; track $index) {
    <button
        #buttons
        class="aix-pagination__button u-color-tertiary-black page br-primary__box-shadow-color--active"
        data-testing="pagination-button-page"
        [class.is-current]="p.num === currentPage()"
        [class.br-secondary--light__background-color]="p.num === currentPage()"
        (click)="goToPage.emit(p.num)"
    >
        {{ p.label }}
    </button>
    }
    <button
        #buttons
        class="aix-pagination__button u-color-tertiary-black -arrow br-primary__color"
        (click)="goToNextPage.emit()"
        aria-label="Next"
    >
        <i class="fa-regular fa-chevron-right"></i>
    </button>
    <button
        #buttons
        class="aix-pagination__button u-color-tertiary-black -last br-primary__color"
        (click)="goToLastPage.emit()"
    >
        Last
    </button>
</div>
}
