import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { ResponseWithHeaders } from '../models/response-with-headers';
import { type } from '../models/type-cache';
import {
    AixCheckboxItem,
    ExploreFilterOptions,
    ExploreSend,
    Product,
    ProductExploreMaterial,
    ProductExploreRecord,
    ProductFamily,
    ProductHeaders,
    ProductRecord,
    ProductsFilterOptions,
    SendExploreRecord
} from '../../models/product/model';
import { AixCheckboxItemQueue } from '@trade-platform/ui-components';

export class ProductRecordActionTypes {
    static readonly LOAD_PRODUCTS = type('[Products] Load Products');
    static readonly LOAD_PRODUCTS_SUCCESS = type('[Products] Load Products Success');
    static readonly LOAD_PRODUCTS_FAIL = type('[Products] Load Products Failure');

    static readonly LOAD_PRODUCT_FAMILY = type('[Products] Load Product Family');
    static readonly LOAD_PRODUCT_FAMILY_SUCCESS = type('[Products] Load Product Family Success');
    static readonly LOAD_PRODUCT_FAMILY_FAIL = type('[Products] Load Product Family Failure');
    static readonly LOAD_PRODUCT_FAMILY_RESET = type('[Products] Load Product Family Reset');

    static readonly LOAD_PRODUCT = type('[Product] Load Product');
    static readonly LOAD_PRODUCT_SUCCESS = type('[Product] Load Product Success');
    static readonly LOAD_PRODUCT_FAIL = type('[Product] Load Product Failure');

    static readonly LOAD_PRODUCT_FILTERS = type('[Product] Load Product Filters');
    static readonly LOAD_PRODUCT_FILTERS_SUCCESS = type('[Product] Load Product Filters Success');
    static readonly LOAD_PRODUCT_FILTERS_FAIL = type('[Product] Load Product Filters Failure');

    static readonly LOAD_PRODUCTS_EXPLORE = type('[Products] Load Products Explore');
    static readonly LOAD_PRODUCTS_EXPLORE_SUCCESS = type(
        '[Products] Load Products Explore Success'
    );
    static readonly LOAD_PRODUCTS_EXPLORE_FAIL = type('[Products] Load Products Explore Failure');

    static readonly LOAD_PRODUCT_EXPLORE_FILTERS = type('[Product] Load Product Explore Filters');
    static readonly LOAD_PRODUCT_EXPLORE_FILTERS_SUCCESS = type(
        '[Product] Load Product Explore Filters Success'
    );
    static readonly LOAD_PRODUCT_EXPLORE_FILTERS_FAIL = type(
        '[Product] Load Product Explore Filters Failure'
    );

    static readonly LOAD_PRODUCT_EXPLORE = type('[Product] Load Product Explore');
    static readonly LOAD_PRODUCT_EXPLORE_SUCCESS = type('[Product] Load Product Explore Success');
    static readonly LOAD_PRODUCT_EXPLORE_FAIL = type('[Product] Load Product Explore Failure');

    static readonly SEND_PRODUCT_EXPLORE = type('[Product] Send Product Explore');
    static readonly SEND_PRODUCT_EXPLORE_SUCCESS = type('[Product] Send Product Explore Success');
    static readonly SEND_PRODUCT_EXPLORE_FAIL = type('[Product] Send Product Explore Failure');

    static readonly LOAD_PRODUCT_EXPLORE_MATERIALS = type(
        '[Product] Load Product Explore Materials'
    );
    static readonly LOAD_PRODUCT_EXPLORE_MATERIALS_SUCCESS = type(
        '[Product] Load Product Explore Materials Success'
    );
    static readonly LOAD_PRODUCT_EXPLORE_MATERIALS_FAIL = type(
        '[Product] Load Product Explore Materials Failure'
    );
}

export type ProductsFilterType =
    | 'externalFirmId'
    | 'fundStructure'
    | 'securityStatus'
    | 'fundSponsor';

export interface ProductsFilter {
    availableActions: AixCheckboxItemQueue[];
    favorite: AixCheckboxItemQueue[];
    externalFirmId: AixCheckboxItemQueue[];
    fundStructure: AixCheckboxItemQueue[];
    securityStatus: AixCheckboxItemQueue[];
    fundSponsor: AixCheckboxItemQueue[];
}

export type ProductRecordActions =
    | LoadProductsAction
    | LoadProductsSuccessAction
    | LoadProductsFailureAction
    | LoadProductFamilyAction
    | LoadProductFamilySuccessAction
    | LoadProductFamilyFailureAction
    | LoadProductFamilyResetAction
    | LoadProductAction
    | LoadProductSuccessAction
    | LoadProductFailureAction
    | LoadProductFiltersAction
    | LoadProductFiltersSuccessAction
    | LoadProductFiltersFailureAction
    | LoadProductsExploreAction
    | LoadProductsExploreSuccessAction
    | LoadProductsExploreFailureAction
    | LoadProductExploreFiltersAction
    | LoadProductExploreFiltersSuccessAction
    | LoadProductExploreFiltersFailureAction
    | LoadProductExploreAction
    | LoadProductExploreSuccessAction
    | LoadProductExploreFailureAction
    | SendProductExploreAction
    | SendProductExploreSuccessAction
    | SendProductExploreFailureAction
    | LoadProductExploreMaterialsAction
    | LoadProductExploreMaterialsSuccessAction
    | LoadProductExploreMaterialsFailureAction;

export class LoadProductsAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS;

    constructor(public orderType?: string, public filters?: ProductsFilterOptions) {}
}

export class LoadProductsSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS_SUCCESS;

    constructor(public payload: ProductRecord[]) {}
}

export class LoadProductsFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductFamilyAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FAMILY;

    constructor(public fundId: string) {}
}

export class LoadProductFamilySuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_SUCCESS;

    constructor(public payload: ProductFamily) {}
}

export class LoadProductFamilyFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductFamilyResetAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_RESET;

    constructor() {}
}

export class LoadProductAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT;

    constructor(public productId: string) {}
}

export class LoadProductSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_SUCCESS;

    constructor(public payload: ResponseWithHeaders<Product, ProductHeaders>) {}
}

export class LoadProductFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductFiltersAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FILTERS;

    constructor(public orderType?: string) {}
}

export class LoadProductFiltersSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FILTERS_SUCCESS;

    constructor(public payload: AixCheckboxItem[]) {}
}

export class LoadProductFiltersFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_FILTERS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// explore
export class LoadProductsExploreAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE;

    constructor(public options?: ExploreFilterOptions) {}
}
export class LoadProductsExploreSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE_SUCCESS;

    constructor(public payload: ProductExploreRecord[]) {}
}
export class LoadProductsExploreFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductExploreFiltersAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS;

    constructor() {}
}
export class LoadProductExploreFiltersSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS_SUCCESS;

    constructor(public payload: AixCheckboxItem[]) {}
}
export class LoadProductExploreFiltersFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductExploreAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE;

    constructor(public payload: { fundSponsorId: string }) {}
}
export class LoadProductExploreSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_SUCCESS;

    constructor(public payload: ProductExploreRecord) {}
}
export class LoadProductExploreFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class SendProductExploreAction implements AixAction {
    readonly type = ProductRecordActionTypes.SEND_PRODUCT_EXPLORE;

    constructor(public payload: ExploreSend) {}
}
export class SendProductExploreSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.SEND_PRODUCT_EXPLORE_SUCCESS;

    constructor(public payload: SendExploreRecord) {}
}
export class SendProductExploreFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.SEND_PRODUCT_EXPLORE_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class LoadProductExploreMaterialsAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS;

    constructor(public payload: { fundSponsorId: string; filter?: string }) {}
}
export class LoadProductExploreMaterialsSuccessAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS_SUCCESS;

    constructor(public payload: ProductExploreMaterial[]) {}
}
export class LoadProductExploreMaterialsFailureAction implements AixAction {
    readonly type = ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
