import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { JsonDecoder } from 'ts.data.json';

@Injectable()
export class DocumentValuesService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: IEnvironment
    ) {}

    loadDocumentValues(payload: string): Observable<string[]> {
        return this.http
            .get<string[]>(`${this.environment.api}documents/values?labelType=${payload}`)
            .pipe(
                concatMap(response => {
                    return fromPromise(
                        JsonDecoder.array(JsonDecoder.string, 'DocumentValues').decodeToPromise(
                            response
                        )
                    );
                })
            );
    }
}
