import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderRecord,
    orderRecordDecoder,
    Page,
    paginationDecoder,
    PaginationOptions
} from '@trade-platform/ui-shared';
import { concatMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class OrdersService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getOrders(opts: PaginationOptions<any>): Observable<Page<OrderRecord>> {
        return this.http
            .post<HttpResponse<Page<OrderRecord>>>(
                `${this.environment.api}orders/listByQueue`,
                {
                    ...opts
                },
                { observe: 'response' }
            )
            .pipe(
                concatMap(response => {
                    return fromPromise(
                        paginationDecoder<OrderRecord>(
                            orderRecordDecoder,
                            parseInt(response.headers?.get('x-total-count') as string, 10),
                            parseInt(response.headers?.get('x-total-pages') as string, 10),
                            'Orders'
                        ).decodeToPromise(response)
                    );
                })
            );
    }
}
