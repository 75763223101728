<div [formGroup]="form">
    <div class="aix-form__group u-mb8">
        <h5>{{ title() }}</h5>
    </div>

    @if (!omitCountry()) {
    <div class="aix-form__container">
        <div class="aix-flex-grid__col u-full-width">
            <div class="aix-form__group">
                <aix-dropdown
                    class="aix-form__control--required standalone-dropdown"
                    [aixPendingField]="{ control: form.get('country') }"
                    [class.aix-form__control--invalid-required]="(form.value.country?.length >= 0 ?
                            !form.value.country?.length :
                            !form.value.country)"
                    [class.aix-form__control--invalid]="(form.value.country?.length >= 0 ?
                            !form.value.country?.length :
                            !form.value.country)"
                    [formControlName]="'country'"
                    [data-testing]="'country'"
                    labelField="name"
                    valueField="id"
                    placeholder=""
                    [options]="countries"
                    (ngModelChange)="onCountryChange($event)"
                >
                </aix-dropdown>
                <label class="aix-form__label aix-label">Country</label>
            </div>
        </div>
    </div>
    }

    <div class="aix-form__container">
        <div class="aix-flex-grid__col u-full-width">
            <div class="aix-form__group">
                <aix-input
                    formControlName="street"
                    [data-testing]="'street'"
                    [aixPendingField]="{ control: form.get('street') }"
                    [isValid]="!!form.get('street')?.valid"
                    [isRequired]="isRequired()"
                    [isDisabled]="isDisabled()"
                >
                    {{ prefix() && prefix() !== '' ? prefix() + ' ' : '' }}{{ includePOBox() ?
                    'Street/PO Box' : 'Street' }}
                </aix-input>
            </div>
        </div>
    </div>

    <div class="aix-form__container">
        <div class="aix-flex-grid__col">
            <div class="aix-form__group">
                <aix-input
                    formControlName="city"
                    [data-testing]="'city'"
                    [aixPendingField]="{ control: form.get('city') }"
                    [isValid]="!!form.get('city')?.valid"
                    [isRequired]="isRequired()"
                    [isDisabled]="isDisabled()"
                >
                    {{ prefix() && prefix() !== '' ? prefix() + ' ' : '' }}City
                </aix-input>
            </div>
        </div>

        <div class="aix-flex-grid__col">
            @if (!form.value?.country || form.value?.country?.length === 0 ||
            (form.value?.country?.length && form.value?.country[0].id === 'US')) {
            <div class="aix-form__group">
                <aix-dropdown
                    #stateDropdownRef
                    class="standalone-dropdown"
                    [aixPendingField]="{ control: form.get('state') }"
                    [class.aix-form__control--required]="isRequired()"
                    [class.aix-form__control--invalid-required]="(form.value.state?.length >= 0 ?
                        !form.value.state?.length :
                        !form.value.state) && isRequired()"
                    [class.aix-form__control--invalid]="(form.value.state?.length >= 0 ?
                        !form.value.state?.length :
                        !form.value.state) && isRequired()"
                    [class.aix-disabled]="isDisabled()"
                    [aixDisabled]="isDisabled()"
                    [formControlName]="'state'"
                    [data-testing]="'state'"
                    labelField="name"
                    valueField="id"
                    placeholder=""
                    [options]="states"
                >
                </aix-dropdown>
                <label class="aix-form__label aix-label"
                    >{{ prefix() && prefix() !== '' ? prefix() + ' ' : '' }} State</label
                >
            </div>
            } @if (form.value?.country?.length && form.value?.country[0].id !== 'US') {
            <div class="aix-form__group">
                <aix-input
                    formControlName="state"
                    [data-testing]="'state'"
                    [aixPendingField]="{ control: form.get('state') }"
                    [isValid]="!!form.get('state')?.valid"
                    [isRequired]="isRequired()"
                    [isDisabled]="isDisabled()"
                >
                    Province / State
                </aix-input>
            </div>
            }
        </div>

        <div class="aix-flex-grid__col">
            @if (!form.value?.country || form.value?.country?.length === 0 ||
            (form.value?.country?.length && form.value?.country[0].id === 'US')) {
            <div class="aix-form__group">
                <aix-zip
                    formControlName="zip"
                    [data-testing]="'zip'"
                    [aixPendingField]="{ control: form.get('zip') }"
                    [isValid]="!!form.get('zip')?.valid"
                    [isRequired]="isRequired()"
                    [isDisabled]="isDisabled()"
                >
                    {{ prefix() && prefix() !== '' ? prefix() + ' ' : '' }}Zip Code
                </aix-zip>

                @if (form.value?.zip && form.get('zip')?.invalid) {
                <div class="form-error--container">
                    <div class="form-error">
                        {{ prefix() && prefix() !== '' ? prefix() + ' ' : '' }}Zip Code field is
                        invalid
                    </div>
                </div>
                }
            </div>
            } @if (form.value?.country?.length && form.value?.country[0].id !== 'US') {
            <div class="aix-form__group">
                <aix-input
                    formControlName="zip"
                    [data-testing]="'zip'"
                    [aixPendingField]="{ control: form.get('zip') }"
                    [isValid]="!!form.get('zip')?.valid"
                    [isRequired]="isRequired()"
                    [isDisabled]="isDisabled()"
                >
                    Postal Code
                </aix-input>
            </div>
            }
        </div>
    </div>
</div>
