import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AixUiComponentsModule } from '@trade-platform/ui-components';
import { AixUiUtilsModule, ENVIRONMENT, IEnvironment, LogService } from '@trade-platform/ui-utils';
import { RemoteDataModule } from 'ngx-remotedata';
import { AixErrorBoxComponent } from './components/error-box/error-box';
import { IconFieldDecorationComponent } from './components/field-decorations/icon-decoration';
import { RemoteDataInProgressFieldDecorationComponent } from './components/field-decorations/in-progress-field-decoration';
import { AixProgressComponent } from './components/progress/progress';
import { AixStepProgressComponent } from './components/progress/step-progress';
import { IsFormFailurePipe } from './components/remote-data/pipes';
import { CacheInterceptor } from './services/cache-interceptor';
import { DocumentViewerDownloadService } from './store/document-viewer/service';
import { LatestActionsService } from './store/utils/latest-actions.service';
import { AixUIDocumentViewerComponent } from './components/document-viewer/document-viewer';
import { AixUIDocumentActionBarComponent } from './components/document-viewer/document-action-bar/document-action-bar.component';
import { AixUIDocumentTabsComponent } from './components/document-viewer/document-tabs/document-tabs.component';
import { AixUIDocumentListComponent } from './components/document-viewer/document-list/document-list.component';
import { AixProductCardComponent } from './components/product-card/product-card';
import { AixProductCardImageComponent } from './components/product-image-card/product-image-card';
import { ProductMaterialsService } from './store/product-materials/service';
import { AixAddressGroupComponent } from './components/aix-address/address';
import { AixFilterSidebarComponent } from './components/aix-filter-sidebar/filter-sidebar.component';
import { DocumentValuesService } from './store/document-values/service';

const LIB_PROVIDERS = [
    Title,
    CacheInterceptor,
    LatestActionsService,
    LogService,
    DocumentViewerDownloadService,
    ProductMaterialsService,
    DocumentValuesService
];

const PIPES = [IsFormFailurePipe];

const COMPONENTS = [
    AixErrorBoxComponent,
    AixProgressComponent,
    AixStepProgressComponent,
    RemoteDataInProgressFieldDecorationComponent,
    IconFieldDecorationComponent,
    AixUIDocumentViewerComponent,
    AixUIDocumentActionBarComponent,
    AixUIDocumentTabsComponent,
    AixUIDocumentListComponent,
    AixProductCardComponent,
    AixProductCardImageComponent,
    AixAddressGroupComponent,
    AixFilterSidebarComponent
];

export interface AixSharedLibConfig {
    environment: IEnvironment;
}

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        AixUiComponentsModule,
        AixUiUtilsModule,
        RemoteDataModule,
        ...COMPONENTS,
        ...PIPES
    ],
    exports: [...COMPONENTS, ...PIPES],
    providers: LIB_PROVIDERS
})
export class AixSharedLibModule {
    static forRoot(config: AixSharedLibConfig): ModuleWithProviders<AixSharedLibModule> {
        return {
            ngModule: AixSharedLibModule,
            providers: [{ provide: ENVIRONMENT, useValue: config.environment }]
        };
    }
}
