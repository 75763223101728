import { JsonDecoder } from 'ts.data.json';
import { FundSponsor } from '../../models/fund-sponsor/model';

export const fundSponsorDecoder = JsonDecoder.object<FundSponsor>(
    {
        id: JsonDecoder.optional(
            JsonDecoder.oneOf(
                [JsonDecoder.number, JsonDecoder.string.map(n => parseInt(n))],
                'string | number'
            )
        ),
        name: JsonDecoder.string,
        shortName: JsonDecoder.optional(JsonDecoder.string),
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        phone1: JsonDecoder.optional(JsonDecoder.string),
        phone2: JsonDecoder.optional(JsonDecoder.string),
        fax1: JsonDecoder.optional(JsonDecoder.string),
        fax2: JsonDecoder.optional(JsonDecoder.string),
        url: JsonDecoder.optional(JsonDecoder.string),
        dstFundSponsorNumber: JsonDecoder.optional(JsonDecoder.string),
        transferAgentFundSponsorId: JsonDecoder.optional(JsonDecoder.string),
        nsccNumber: JsonDecoder.optional(JsonDecoder.string),
        logoUrl: JsonDecoder.optional(JsonDecoder.string),
        dstUsername: JsonDecoder.optional(JsonDecoder.string),
        dstBusinessArea: JsonDecoder.optional(JsonDecoder.string),
        alwaysFallback: JsonDecoder.optional(JsonDecoder.boolean),
        isDemo: JsonDecoder.optional(JsonDecoder.boolean),
        email: JsonDecoder.optional(JsonDecoder.string),
        description: JsonDecoder.optional(JsonDecoder.string),
        contactInformation: JsonDecoder.optional(
            JsonDecoder.object(
                {
                    contactType: JsonDecoder.optional(JsonDecoder.string),
                    name: JsonDecoder.optional(JsonDecoder.string),
                    email: JsonDecoder.optional(JsonDecoder.string),
                    phoneNumber: JsonDecoder.optional(JsonDecoder.string),
                    additionalInfo: JsonDecoder.optional(JsonDecoder.string)
                },
                'contactInformation'
            )
        )
    },
    'FundSponsor'
);
