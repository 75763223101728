import { Component, input, TemplateRef } from '@angular/core';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { AixCitizenValuePipe, AixKebabCasePipe } from '@trade-platform/ui-utils';

export interface DataOverviewElementHeaderTemplateContext {
    title: string;
    subtitle?: string;
}

export interface DataOverviewElementValueTemplateContext {
    value: string;
}

export interface DataOverviewElement {
    title: string;
    subtitle?: string;
    values: string[];
    headerTemplate?: TemplateRef<DataOverviewElementHeaderTemplateContext>;
    infoTemplate?: TemplateRef<DataOverviewElementValueTemplateContext>;
    classList?: string[];
}

@Component({
    selector: 'aix-readonly-data-overview',
    templateUrl: './aix-readonly-data-overview.component.html',
    styleUrls: ['./aix-readonly-data-overview.component.scss'],
    standalone: true,
    imports: [
        AixDataTestingDirective,
        NgIf,
        NgFor,
        NgClass,
        NgTemplateOutlet,
        AixKebabCasePipe,
        AixCitizenValuePipe
    ]
})
export class AixReadonlyDataOverviewComponent {
    elements = input<DataOverviewElement[]>([]);
    headerTemplate = input<TemplateRef<DataOverviewElementHeaderTemplateContext> | null>();
    infoTemplate = input<TemplateRef<DataOverviewElementValueTemplateContext> | null>();

    constructor() {}
}
