import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { ResponseWithHeaders } from '../models/response-with-headers';
import { ProductRecordActions, ProductRecordActionTypes } from './actions';
import {
    AixCheckboxItem,
    Product,
    ProductExploreMaterial,
    ProductExploreRecord,
    ProductFamily,
    ProductHeaders,
    ProductRecord,
    SendExploreRecord
} from '../../models/product/model';

export type ProductsState = RemoteData<ProductRecord[], ErrorWrapper>;

export function productsReducer(
    state: ProductsState = notAsked(),
    action: ProductRecordActions
): ProductsState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCTS:
            return inProgress([]);
        case ProductRecordActionTypes.LOAD_PRODUCTS_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCTS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type ProductFamilyState = RemoteData<ProductFamily, ErrorWrapper>;

export function productFamilyReducer(
    state: ProductFamilyState = notAsked(),
    action: ProductRecordActions
): ProductFamilyState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT_FAMILY:
            return inProgress({} as ProductFamily);
        case ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_FAIL:
            return failure(action.payload.error);
        case ProductRecordActionTypes.LOAD_PRODUCT_FAMILY_RESET:
            return notAsked();
        default:
            return state;
    }
}

export type ProductState = RemoteData<ResponseWithHeaders<Product, ProductHeaders>, ErrorWrapper>;

export function productReducer(
    state: ProductState = notAsked(),
    action: ProductRecordActions
): ProductState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT:
            return inProgress();
        case ProductRecordActionTypes.LOAD_PRODUCT_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type ProductFiltersState = RemoteData<AixCheckboxItem[], ErrorWrapper>;
export function productFiltersReducer(
    state: ProductFiltersState = notAsked(),
    action: ProductRecordActions
): ProductFiltersState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT_FILTERS:
            return inProgress();
        case ProductRecordActionTypes.LOAD_PRODUCT_FILTERS_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_FILTERS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

// explore
export type ProductsExploreState = RemoteData<ProductExploreRecord[], ErrorWrapper>;
export function productsExploreReducer(
    state: ProductsExploreState = notAsked(),
    action: ProductRecordActions
): ProductsExploreState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE:
            return inProgress([]);
        case ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type ProductExploreFiltersState = RemoteData<AixCheckboxItem[], ErrorWrapper>;
export function productExploreFiltersReducer(
    state: ProductExploreFiltersState = notAsked(),
    action: ProductRecordActions
): ProductFiltersState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS:
            return inProgress();
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type ProductExploreState = RemoteData<ProductExploreRecord, ErrorWrapper>;
export function productExploreReducer(
    state: ProductExploreState = notAsked(),
    action: ProductRecordActions
): ProductExploreState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE:
            return inProgress();
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type SendProductExploreState = RemoteData<SendExploreRecord, ErrorWrapper>;
export function sendProductExploreReducer(
    state: SendProductExploreState = notAsked(),
    action: ProductRecordActions
): SendProductExploreState {
    switch (action.type) {
        case ProductRecordActionTypes.SEND_PRODUCT_EXPLORE:
            return inProgress();
        case ProductRecordActionTypes.SEND_PRODUCT_EXPLORE_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.SEND_PRODUCT_EXPLORE_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type ProductExploreMaterialsState = RemoteData<ProductExploreMaterial[], ErrorWrapper>;
export function productExploreMaterialsReducer(
    state: ProductExploreMaterialsState = notAsked(),
    action: ProductRecordActions
): ProductExploreMaterialsState {
    switch (action.type) {
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS:
            return inProgress();
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS_SUCCESS:
            return success(action.payload);
        case ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}
